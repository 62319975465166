import Swal from "sweetalert2";

/**
 * Valida el tamaño de un archivo.
 * @param {File} file - Archivo a validar.
 * @param {number} maxSize - Tamaño máximo permitido en bytes.
 * @param {number} maxWidth - Ancho máximo permitido en píxeles.
 * @param {number} maxHeight - Alto máximo permitido en píxeles.
 * @returns {boolean} - True si el archivo es válido, false si no.
 */
export const validateFileSize = (file, maxSize) => {
    return file.size <= maxSize;
};

/**
 * Muestra un mensaje de error si el archivo supera el tamaño máximo.
 * @param {number} maxSize - Tamaño máximo permitido en bytes.
 */
export const showFileSizeError = (maxSize) => {
    const maxSizeMB = maxSize / (1024 * 1024);
    Swal.fire({
        icon: "error",
        title: "Archivo demasiado grande",
        text: `El archivo no puede superar los ${maxSizeMB} MB.`,
        confirmButtonText: "Aceptar",
    });
};

/**
 * Valida las dimensiones de una imagen.
 * @param {File} file - Archivo de imagen a validar.
 * @param {number} maxWidth - Ancho máximo permitido en píxeles.
 * @param {number} maxHeight - Alto máximo permitido en píxeles.
 * @returns {Promise<boolean>} - True si la imagen es válida.
 */
export const validateImageResolution = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const isValid = img.naturalWidth <= maxWidth && img.naturalHeight <= maxHeight;
        URL.revokeObjectURL(img.src);
        resolve(isValid);
      };
      img.onerror = () => resolve(false);
    });
  };
  
  /**
   * Muestra un error de resolución.
   * @param {number} maxWidth - Ancho máximo permitido.
   * @param {number} maxHeight - Alto máximo permitido.
   */
  export const showResolutionError = (maxWidth, maxHeight) => {
    Swal.fire({
      icon: "error",
      title: "Resolución no permitida",
      text: `La imagen no puede superar ${maxWidth}x${maxHeight} píxeles.`,
      confirmButtonText: "Aceptar",
    });
  };